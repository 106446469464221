




















































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import ExerciseTaskForm from '@/components/forms/exercise/ExerciseTaskForm.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import CourseProgramMonthMixin from '@/mixins/manager/CourseProgramMonthMixin'
import ExerciseEditMixin from '@/mixins/manager/ExerciseEditMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { ExerciseStore } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import { convertDateToMSK, convertHoursMinutesToSeconds, parseDateToMilliseconds } from '@/utils/functions'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Confirmation,
    ExerciseForm,
    ExerciseTaskForm,
    Parameters,
  },
})
export default class CoursesItemProgramMonthExercisesItemEdit extends Mixins(CourseProgramMonthMixin, ExerciseEditMixin) {
  private get exerciseUUID () {
    return this.$route.params.exerciseUUID
  }

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get isSpendingAt() {
    if (this.exerciseCourse) {
      return parseDateToMilliseconds(this.exerciseCourse.spendingAt) < this.currentTime
    }
    return false
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private autoCheck = false

  private isLoading = true

  private mounted () {
    this.$bus.$on('changeAutoCheck', this.handleAutoCheck)
    this.$bus.$on('addMaterials', this.addMaterials)
    DictionaryModule.fetchConstants()
      .then(() => {
        this.fetchExercise()
      })
      .catch(this.notifyError)
  }

  private destroyed() {
    this.$bus.$off('changeAutoCheck', this.handleAutoCheck as any)
    this.$bus.$off('addMaterials', this.addMaterials as any)
    this.$bus.$off('add-exercise-from-template', this.fetchMonth as any)
  }

  private handleAutoCheck(value: boolean) {
    this.autoCheck = value
  }

  private fetchExercise () {
    if (!this.exerciseCourse || this.exerciseCourse.uuid !== this.exerciseUUID) {
      ManagerExercisesModule.setExercise(null)
      MentorExercisesModule.setExercise(null)
      this.isLoading = true
      ManagerCoursesModule.fetchExercise({
        courseID: this.courseID,
        exerciseUUID: this.exerciseUUID,
        monthID: this.monthID,
      })
        .then(() => {
          if (!this.exerciseCourse?.tasks.length) {
            this.handleAddTask('exerciseCourse')
          } else {
            this.syncTasks('exerciseCourse')
          }

          this.autoCheck = this.exerciseCourse ? this.exerciseCourse.autoCheck : false
        })
        .catch((error: any) => {
          this.notifyError(error)
          this.$router.push({
            name: 'manager.control.courses.item.program.month.exercises',
            params: {
              courseID: this.courseID.toString(),
              monthID: this.monthID.toString(),
            },
          })
        })
        .finally(() => this.isLoading = false)

    } else {
      this.isLoading = false
      if (!this.exerciseCourse.tasks.length) {
        this.handleAddTask('exerciseCourse')
      } else {
        this.syncTasks('exerciseCourse')
      }
      this.autoCheck = this.exerciseCourse.autoCheck
    }
  }

  // Сохранение контейнера дз
  private handleExerciseSubmit(form: ExerciseStore) {
    if (this.exerciseCourse) {
      const formSubmit = {
        ...form,
        avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
        deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
        spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
      }

      ManagerCoursesModule.saveExercise({
        courseID: this.courseID,
        exerciseUUID: this.exerciseCourse.uuid,
        monthID: this.monthID,
        params: formSubmit,
      })
        .then(response => {
          if (response.tasks.length) {
            this.syncTasks('exerciseCourse')
          }
          this.notifySuccess('Основа задания сохранена')
        })
        .catch(this.notifyError)
    }
  }

  private handleExerciseDelete () {
    if (this.exerciseCourse) {
      ManagerCoursesModule.deleteExercise({
        courseID: this.courseID,
        exerciseUUID: this.exerciseCourse.uuid,
        monthID: this.monthID,
      })
        .then(() => {
          this.notifySuccess('Задание удалено')
          this.$router.push({
            name: 'manager.control.courses.item.program.month.exercises',
            params: {
              courseID: this.courseID.toString(),
              monthID: this.monthID.toString(),
            },
          })
        })
        .catch(this.notifyError)
    }
  }

  private confirmDeleteExercise () {
    this.confirm.open(
      'Удаление задания',
      `Вы уверены, что хотите удалить задание <span class="secondary--text font-weight-semibold">${this.exerciseCourse ? this.exerciseCourse.title : ''}</span>? После удаления, задание нельзя будет восстановить.`,
      {
        buttonConfirmText: 'Удалить',
        skin: 'secondary',
      },
    )
      .then(this.handleExerciseDelete)
      .catch(() => {return})
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Редактирование домашнего задания. ${this.monthName}. ${this.month ? this.courseTitle : ''}`,
    }
  }
}
